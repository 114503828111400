import { createReducer } from 'redux-starter-kit';
import { authActions } from '../actions';

const {
  authSuccess, authFailure, setLastAuthUserId, resyncAuthUser,
} = authActions;

export const usersInitialState = {
  authUser: null,
  lastAuthUserId: null,
};

const authSuccessClbk = (state, action) => ({
  ...state,
  authUser: action.payload,
});

const authFailureClbk = (state) => ({ ...state, authUser: null });

const setLastAuthUserIdClbk = (state, action) => ({
  ...state,
  lastAuthUserId: action.payload,
});

const resyncAuthUserClbk = (state, action) => ({
  ...state,
  authUser: action.payload,
});

const usersReducer = createReducer(usersInitialState, {
  [authSuccess]: authSuccessClbk,
  [authFailure]: authFailureClbk,
  [setLastAuthUserId]: setLastAuthUserIdClbk,
  [resyncAuthUser]: resyncAuthUserClbk,
});

export default usersReducer;
