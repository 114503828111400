import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { childrenPropTypes } from '../utils/generic-prop-types';


class AuthContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: childrenPropTypes(),
    top: PropTypes.shape({
      subTitle: childrenPropTypes(),
      info: childrenPropTypes().isRequired,
    }).isRequired,
    bottom: PropTypes.shape({
      navContent: PropTypes.element,
    }).isRequired,
  };

  static defaultProps = {
    className: 'small-width',
    children: null,
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { top, bottom, children } = this.props;

    return (
      <div className={this.props.className}>
        <div className="text-center text-white pt-2 pb-2">
          {top.subTitle && (
            <h4 className="my-2">
              {top.subTitle}
            </h4>
          )}
          <div className="lead mt-2 mb-1">
            {top.info}
          </div>
        </div>
        <div className="authentication-content-body text-white">
          {children}
        </div>
        <div className="text-center pt-3">
          <div className="authentication-buttons">
            {bottom.navContent}
          </div>
        </div>
      </div>
    );
  }
}


export default AuthContainer;
